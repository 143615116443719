<template>
    <div>
        <Navbar page="Assinatura" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
                <h1 class="font-r font-medium text-3xl mb-8">Assinatura Fácil LGPD</h1>
                <div v-if="user && !user.assinatura.ativo">
                    <h3 v-if="$store.state.user.data && $moment($store.state.user.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') >= 0" class="text-gray-800 font-r font-medium text-xl mb-2">
                        Dias de Teste Gratis disponível: {{ $moment($store.state.user.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') > 0 ? $moment($store.state.user.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') : 'Finalizando' }}
                    </h3>
                    <h3 class="text-gray-800 font-r font-medium text-xl mb-8">Assinatura desativada, selecione o plano para ativá-lo: </h3>
                    <div class="grid grid-cols-12 gap-6">
                        <div v-for="item in list" :key="item.id" class="col-span-12 md:col-span-6">
                            <div class="border border-gray-100 shadow-lg rounded py-5 px-4">
                                <h3 class="text-gray-800 text-center font-r font-medium text-2xl">{{ item.name }} <span v-if="item.interval === 'year'" class="text-red-500 text-lg">20% de desconto</span> </h3>
                                <hr class="my-5" />
                                <p class="text-gray-700 font-r font-medium text-base py-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="inline h-4 w-4 mx-2" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Multiprojetos (ilimitados projetos ao mesmo tempo) 
                                </p>
                                <p class="text-gray-700 font-r font-medium text-base py-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="inline h-4 w-4 mx-2" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Múltiplos profissionais no projetos 
                                </p>
                                <p class="text-gray-700 font-r font-medium text-base py-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="inline h-4 w-4 mx-2" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                    </svg>
                                    White Label (sua marca e suas cores na plataforma)
                                </p>
                                <p class="text-gray-700 font-r font-medium text-base py-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="inline h-4 w-4 mx-2" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Assinatura mensal, cancela quando quiser
                                </p>
                                <p class="text-gray-700 font-r font-medium text-base py-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="inline h-4 w-4 mx-2" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Sem multas ou asteriscos 
                                </p>
                                <hr class="my-5" />
                                <div class="grid grid-cols-12 gap-4">
                                    <!-- <div class="col-span-12 md:col-span-6">
                                        <p class="mx-2 text-green-700 font-r font-semibold text-2xl">{{ formatPrice(item.minimum_price / 100) }} </p>
                                    </div> -->
                                    <div class="col-span-12 md:col-span-12 mx-auto">
                                        <a target="_blank" :href="`https://wa.me/5542999021266?text=Olá, gostaria de assinar o plano: ${item.name} da FácilLGPD!`" class="text-white hover:bg-green-500 bg-green-700 rounded py-2 px-4">
                                            Quero {{item.name}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="user && user.assinatura.ativo">
                    <div class="border border-gray-100 shadow-lg rounded py-5 px-4 md:w-3/4 2xl:w-1/2">
                        <h3 class="text-gray-800 font-r font-medium text-2xl mb-2">Plano: {{ user.assinatura.plano_nome }} </h3>
                        <hr class="my-5" />
                        <h3 v-if="user.assinatura.status !== 3" class="text-gray-800 font-r font-medium text-xl mb-2">Pago: {{ user.assinatura.pago ? 'Sim': 'Não' }} <span v-if="user.assinatura.pago && user.assinatura.pagoDia" class="text-sm"> - Pagamento: {{ user.assinatura.pagoDia | moment("DD/MM/YYYY") }}</span> </h3>
                        <h3 v-if="user.assinatura.payment_method" class="text-gray-800 font-r font-medium text-xl mb-2">Método: {{ user.assinatura.payment_method === 'boleto' ? 'Boleto' : 'Cartão de Crédito' }} </h3>
                        <h3 v-if="user.assinatura.status === 2" class="text-gray-800 font-r font-medium text-xl mb-2">Próximo pagamento: {{ user.assinatura.validade | moment("DD/MM/YYYY") }} </h3>
                        <p v-if="user.assinatura.status === 2 && user.assinatura.payment_method === 'boleto'" class="text-gray-800 text-sm font-r mb-2">Na data de pagamento {{ user.assinatura.validade | moment("DD/MM/YYYY") }} você recebera uma notificação no seu email e na plataforma com o boleto.</p>
                        <h3 class="text-gray-800 font-r font-medium text-xl mb-2">Status: {{ getStatus(user.assinatura.status) }} </h3>
                        <h3 v-if="getStatus(user.assinatura.status) === 'Processando Assinatura'" class="text-gray-800 font-r font-medium text-base mb-2">Aguarde a notificação de confirmação da assinatura no seu email ou plataforma.</h3>
                        <p v-if="user.assinatura.status === 4 && user.assinatura.payment_method === 'credit_card'" class="text-gray-800 text-sm font-r font-semibold mb-2">Durante 5 dias, novas tentativas de pagamentos serão feitas!</p>
                        <h3 v-if="user.assinatura.status !== 2 && user.assinatura.boleto_url" class="text-gray-800 font-r font-medium text-xl mb-2">Boleto: <a :href="user.assinatura.boleto_url" target="_blank" class="text-blue-500 underline">{{ user.assinatura.boleto_url }}</a></h3>
                        <h3 v-if="user.assinatura.status !== 2 && user.assinatura.boleto_expiration_date" class="text-gray-800 font-r font-medium text-xl mb-2">Validade boleto: {{ user.assinatura.boleto_expiration_date | moment("DD/MM/YYYY") }} </h3>
                        <button @click="cancelar" type="button" class="text-xs text-white hover:bg-red-500 bg-red-700 rounded py-1 px-3 mt-3">
                            Cancelar Plano
                        </button>
                    </div>
                </div>
                <h3 v-if="historico.length" class="text-gray-800 font-r font-medium text-2xl my-4"> Histórico </h3>
                <div v-if="historico.length" class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    # ID
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    Plano
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    Pago
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    Pago Dia
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    Status
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    Data
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="item in historico" :key="item._id">
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div class="text-sm text-gray-500">
                                            {{item.subscriptions ? item.subscriptions : ''}}
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div class="text-sm text-gray-500">
                                            {{item.plano_nome ? item.plano_nome : ''}}
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div class="text-sm text-gray-500" :class="item.pago ? 'font-semibold text-green-500' : 'font-semibold text-red-500'">
                                            {{item.pago ? 'Sim' : 'Não' }}
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div v-if="item.pagoDia" class="text-sm text-gray-500">
                                            {{ item.pagoDia | moment("DD/MM/YYYY") }}
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div class="text-sm text-gray-500" :class="{'text-red-500': getStatus(item.status) === 'Plano cancelado' || getStatus(item.status) === 'Falha pagamento', 'text-blue-500': getStatus(item.status) === 'Aguardando pagamento' || getStatus(item.status) === 'Processando Assinatura' || getStatus(item.status) === 'Processando Pagamento', 'text-green-500': getStatus(item.status) === 'Plano pago'}">
                                            {{ getStatus(item.status) }}
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div v-if="item.data" class="text-sm text-gray-500">
                                            {{ item.data | moment("DD/MM/YYYY") }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            route: 'pagarme',
            user: null,
            list: [],
            historico: [],
        }
    },
    methods: {
        async start() {
            const listReq = await this.$http.post(`/v1/${this.route}/list`);
            this.list = listReq.data.data;

            const userReq = await this.$http.post('/v1/users/me');
            this.user = userReq.data;

            const listHistReq = await this.$http.post('/v1/users/listHistorico');
            this.historico = listHistReq.data.data;
        },
        async cancelar() {
            this.$confirm({
                title: 'Desativar Assinatura',
                message: `Tem certeza que deseja cancelar seu plano de assinatura?`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    if (confirm) {
                        const resp = await this.$http.post(`/v1/pagarme/cancelar`);
                        if (resp.data.success) {
                            this.$vToastify.success(resp.data.msg);
                            this.$store.commit('setPago', false);
                            this.start();
                        } else {
                            this.$vToastify.error(resp.data.err);
                        }
                    }
                }
            });
        },
        getStatus(status){
            switch(status){
                case 1: return 'Plano cancelado';
                case 2: return 'Plano pago';
                case 3: return 'Aguardando pagamento';
                case 4: return 'Falha pagamento';
                case 5: return 'Processando Pagamento';
                case 6: return 'Processando Assinatura';
            }
        }
    },
    async beforeRouteLeave (to, from, next) {
        if(from.name === 'Assinatura' && !this.user.assinatura.pago){
            this.$vToastify.info("Faça sua Assinatura Fácil LGPD");
        }
        next();
    },
    async beforeMount() {
        this.start();
    }
}
</script>
